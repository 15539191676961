import { Injectable } from '@angular/core';

import { FormatNumberBrowserLocalePipe, LoaderStateService, TranslateService, UnitSymbolPipe } from '@novisto/common';
import { Observable, defer, from, lastValueFrom, map, of } from 'rxjs';

import {
  EmbedderFontColors,
  EmbedderHighlightColors,
  EmbedderInsertOptions,
  EmbedderValue,
  EmbedderValueField,
  EmbedderValueId,
  ExcelEmbeddedValues,
  IContextSettings,
  MinimalDocumentMetaData,
  UpdateEmbedderValuesOptions,
} from '../../models';
import { EmbedderService } from '../embedder/embedder.service';
import { EmbedderUtils } from '../../utilities/embedder-utils';
import { PublicDocumentsService } from '../public-documents/public-documents.service';
import { PublicIndicatorsService } from '../public-indicators/public-indicators.service';
import { PublicFiscalYearsService } from '../public-fiscal-years/public-fiscal-years.service';
import { PublicSourcesService } from '../public-source/public-source.service';
import keyBy from 'lodash/keyBy';
import chunk from 'lodash/chunk';
import range from 'lodash/range';

@Injectable({ providedIn: 'root' })
export class ExcelEmbedderService extends EmbedderService {
  public static readonly EMBEDDED_VALUES_KEY = 'embedded-values';
  private static readonly SHEET_DIVIDER = '!';

  constructor(
    formatNumberBrowserLocalePipe: FormatNumberBrowserLocalePipe,
    loaderStateService: LoaderStateService,
    publicDocumentsService: PublicDocumentsService,
    publicFiscalYearsService: PublicFiscalYearsService,
    publicIndicatorsService: PublicIndicatorsService,
    publicSourcesService: PublicSourcesService,
    translateService: TranslateService,
    unitSymbolPipe: UnitSymbolPipe,
  ) {
    super(
      formatNumberBrowserLocalePipe,
      loaderStateService,
      publicDocumentsService,
      publicFiscalYearsService,
      publicIndicatorsService,
      publicSourcesService,
      translateService,
      unitSymbolPipe,
    );
  }

  public embbedFileV2(): Observable<void> {
    return of(undefined);
  }

  public embbedTableAsList(): Observable<void> {
    return of(undefined);
  }

  public embbedTableAsTable(settings: IContextSettings, embedderValue: EmbedderValue): Observable<void> {
    return this.run(async (context: Excel.RequestContext) => {
      const { table } = embedderValue;

      if (!table) {
        return;
      }

      const embeddedValues = await this.getEmbeddedValues();
      const newEmbeddedValues = { ...embeddedValues };
      const rows = table.length + 1;
      const cols = Number(table[0].values?.length);
      const { ids: embedderValueIds, values: tableValues } = this.getTableValues(settings, embedderValue, table);
      const tableRange = context.workbook
        .getActiveCell()
        .getCell(0, 0)
        .getResizedRange(rows - 1, cols - 1);
      const worksheet = tableRange.worksheet;

      context.load(worksheet);
      await context.sync();

      for (const row of range(0, rows)) {
        for (const col of range(0, cols)) {
          const cell = tableRange.getCell(row, col);
          context.load(cell);
          await context.sync();

          const id = embedderValueIds[`${row}-${col}`];
          const cellId = this.getCellId(worksheet, cell);

          if (newEmbeddedValues[cellId]) {
            delete newEmbeddedValues[cellId];

            if (this.highlightControls) {
              this.setHighlightColor(cell, EmbedderHighlightColors.none);
            }
          }

          if (id) {
            newEmbeddedValues[cellId] = id;
            this.setHighlightColor(cell);
          }
        }
      }

      tableRange.values = chunk(tableValues, cols);
      await context.sync();
      await this.saveEmbeddedValues(newEmbeddedValues);
    });
  }

  public embbedValue(
    settings: IContextSettings,
    documents: Record<string, MinimalDocumentMetaData>,
    embedderValue: EmbedderValue,
    field: EmbedderValueField,
    fieldId?: string,
    options: EmbedderInsertOptions = {},
  ): Observable<void> {
    const { value: formattedValue } = this.formatValue(embedderValue, field, fieldId, documents, { plainNumber: true });
    const id = EmbedderUtils.formatId(settings, embedderValue, formattedValue, field, fieldId);

    return this.insert(id, Array.isArray(formattedValue) ? formattedValue.join(', ') : formattedValue, options);
  }

  public getEmbedderValueIds(options?: UpdateEmbedderValuesOptions): Observable<EmbedderValueId[]> {
    return this.run(async (context: Excel.RequestContext) => {
      const embeddedValues = await this.getEmbeddedValues();
      const embbededValueIds: EmbedderValueId[] = Object.values(embeddedValues)
        .map((id: string) => EmbedderUtils.fetchId(id))
        .filter((id: EmbedderValueId | undefined) => id) as EmbedderValueId[];
      const embedderValueIds = this.filterEmbedderValueIds(embbededValueIds, options);

      if (options?.updatedValues) {
        for (const embedderValueId of embedderValueIds) {
          if (embedderValueId.embedderValue.value?.value) {
            let updated = false;
            const cells = await this.fetchCurrentEmbeddedValues(
              context,
              embeddedValues,
              EmbedderUtils.encodeId(embedderValueId),
            );

            for (let i = 0; !updated && i < cells.length; i++) {
              const content = cells[i].text[0][0] || '';

              if (content !== embedderValueId.formattedValue) {
                updated = true;
                embedderValueId.embedderValue.value.value = content;
              }
            }
          }
        }
      }

      return embedderValueIds;
    });
  }

  public getSelectedEmbedderValues(): Observable<EmbedderValueId[]> {
    return this.run(async (context) => {
      const embeddedValues = await this.getEmbeddedValues();
      const activeCell = context.workbook.getActiveCell();
      const worksheet = activeCell.worksheet;
      context.load(activeCell);
      context.load(worksheet);
      await context.sync();

      const divider = ExcelEmbedderService.SHEET_DIVIDER;
      const id = embeddedValues[`${worksheet.id}${divider}${activeCell.address.split(divider)[1]}`];
      if (id) {
        const embeddedValueId = EmbedderUtils.fetchId(id);
        return embeddedValueId ? [embeddedValueId] : [];
      }
      return [];
    });
  }

  public setControlsFormat(settings?: IContextSettings): Observable<void> {
    const isOverallUpdate = typeof settings === 'undefined';

    return this.run(async (context) => {
      this.highlightControls = isOverallUpdate ? this.highlightControls : settings.highlightControls;
      const embeddedValues = await this.getEmbeddedValues();
      const cells = await this.fetchCurrentEmbeddedValues(context, embeddedValues);
      cells.forEach((cell) => this.setHighlightColor(cell));
      await context.sync();
    });
  }

  protected highlightControl(controlId: string, highlightColor: EmbedderHighlightColors | null): Observable<void> {
    return this.run(async (context: Excel.RequestContext) => {
      const embeddedValues = await this.getEmbeddedValues();
      const cells = await this.fetchCurrentEmbeddedValues(context, embeddedValues, controlId);
      cells.forEach((cell) => {
        this.setHighlightColor(cell, highlightColor);

        if (highlightColor === EmbedderHighlightColors.deleted) {
          this.setColor(cell, EmbedderFontColors.deleted);
        }
      });

      await context.sync();

      if (highlightColor === EmbedderHighlightColors.deleted) {
        await this.deleteEmbeddedValue(controlId, embeddedValues);
      }
    });
  }

  protected run<T>(execute: (context: Excel.RequestContext) => Promise<T>): Observable<T> {
    return defer(() => from(Excel.run(execute)));
  }

  private insert(id: string, value: string, options: EmbedderInsertOptions): Observable<void> {
    return this.run(async (context: Excel.RequestContext) => {
      const embeddedValues = await this.getEmbeddedValues();
      let cells: Excel.Range[] = [];

      if (options.controlId) {
        cells = await this.fetchCurrentEmbeddedValues(context, embeddedValues, options.controlId);
      } else {
        cells.push(context.workbook.getActiveCell());
      }

      for (const cell of cells) {
        const worksheet = cell.worksheet;
        context.load(cell);
        context.load(worksheet);
        await context.sync();

        cell.values = [[value]];
        this.setHighlightColor(cell, options.highlightColor);
        await context.sync();

        await this.saveEmbeddedValues({ ...embeddedValues, [this.getCellId(worksheet, cell)]: id });
      }
    });
  }

  private async deleteEmbeddedValue(controlId: string, embeddedValues: ExcelEmbeddedValues): Promise<void> {
    const newEmbeddedValues = Object.entries(embeddedValues).reduce((acc: ExcelEmbeddedValues, [key, id]) => {
      if (id !== controlId) {
        acc[key] = id;
      }

      return acc;
    }, {});

    await this.saveEmbeddedValues(newEmbeddedValues);
  }

  private async fetchCurrentEmbeddedValues(
    context: Excel.RequestContext,
    embeddedValues: ExcelEmbeddedValues,
    controlId?: string,
  ): Promise<Excel.Range[]> {
    const cells: Excel.Range[] = [];
    let entries = Object.entries(embeddedValues);

    if (controlId) {
      entries = entries.filter(([_, id]) => controlId === id);
    }

    const worksheets = context.workbook.worksheets;
    context.load(worksheets);
    await context.sync();

    const worksheetsById = keyBy(context.workbook.worksheets.items, 'id');

    for (const entry of entries) {
      const [worksheetId, cellId] = entry[0].split(ExcelEmbedderService.SHEET_DIVIDER);

      if (worksheetsById[worksheetId]) {
        const cell = worksheetsById[worksheetId].getRange(cellId);
        context.load(cell);
        await context.sync();

        cells.push(cell);
      }
    }

    return cells;
  }

  private getCellId(worksheet: Excel.Worksheet, cell: Excel.Range): string {
    const divider = ExcelEmbedderService.SHEET_DIVIDER;
    return `${worksheet.id}${divider}${cell.address.split(divider)[1]}`;
  }

  private getEmbeddedValues(): Promise<ExcelEmbeddedValues> {
    return lastValueFrom(
      this.getSetting<ExcelEmbeddedValues>(ExcelEmbedderService.EMBEDDED_VALUES_KEY).pipe(map((s) => s || {})),
    );
  }

  private saveEmbeddedValues(embeddedValues: ExcelEmbeddedValues): Promise<void> {
    return lastValueFrom(this.saveSetting(ExcelEmbedderService.EMBEDDED_VALUES_KEY, embeddedValues));
  }

  private setColor(range: Excel.Range, color: EmbedderFontColors): void {
    range.format.font.set({ color });
  }

  private setHighlightColor(range: Excel.Range, color?: EmbedderHighlightColors | null): void {
    const highlightColor =
      color ?? (this.highlightControls ? EmbedderHighlightColors.standard : EmbedderHighlightColors.none);

    if (highlightColor) {
      range.format.fill.set({ color: highlightColor });
    } else {
      range.format.fill.clear();
    }
  }
}
